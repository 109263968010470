import { useState, useEffect } from 'react';
import { formatCurrency as currency } from '../utils/formatCurrency';
import { getDisplayPrice, getOrigPrice } from '../utils/amountHelper';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
SelectedUpsells.propTypes = {
  item: PropTypes.object.isRequired,
};

export default function SelectedUpsells({ item }) {
  const [selectedUpsells, setSelectedUpsells] = useState([]);

  useEffect(() => {
    const replaceUpsells = item.upSellProducts
      .filter((upsell) => upsell.upsellSectionId === 1 && upsell.isSelected)
      .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex);

    const accessoryUpsells = item.upSellProducts
      .filter((upsell) => upsell.upsellSectionId === 2 && upsell.isSelected)
      .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex);

    const digitalUpsells = item.upSellProducts
      .filter((upsell) => upsell.upsellSectionId === 3 && upsell.isSelected)
      .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex);

    const shippingUpsells = item.upSellProducts
      .filter((upsell) => upsell.upsellSectionId === 4 && upsell.isSelected)
      .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex);

    const relatedUpsells = item.upSellProducts
      .filter((upsell) => upsell.upsellSectionId === 5 && upsell.isSelected)
      .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex);

    setSelectedUpsells([...replaceUpsells, ...accessoryUpsells, ...digitalUpsells, ...shippingUpsells, ...relatedUpsells]);
  }, [item]);

  const upsellList = selectedUpsells.map((upsell, index) => {
    const isUpsellTypeReplace = upsell.upsellType === 'Replace';
    const upsellDispayPrice = getDisplayPrice(upsell, true);
    const origPrice = getOrigPrice(upsell, true);

    const origPriceDisplay = isUpsellTypeReplace ? origPrice - item.unitPriceWithDiscountAndFeeIfHidden : origPrice;

    const priceDisplay = isUpsellTypeReplace
      ? upsellDispayPrice - item.unitPriceWithDiscountAndFeeIfHidden
      : upsellDispayPrice;

    return (
      <li className="small mb-0" key={index}>
        {upsell.name}{' '}
        <span className="d-inline-block">
          (
          {upsell.upsellType === 'Replace' && upsell.includeUpsell ? (
            'Included'
          ) : (
            <>
              {upsell.discountSource && upsell.discountSource !== 'BuiltIn' && (
                <span className="orig-price d-inline-block me-2">+{currency(origPriceDisplay)}</span>
              )}
              +{currency(priceDisplay)}
            </>
          )}
          )
        </span>
      </li>
    );
  });

  return (
    <>
      {upsellList.length ? (
        <div className="pt-1 mt-2 mb-1">
          <p className="small fw-600 mb-1">Added Course Upgrades & Add-ons</p>
          <ul className="selected-upsell mb-0">{upsellList}</ul>
        </div>
      ) : null}
    </>
  );
}
