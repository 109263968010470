import { useContext } from 'react';
import { formatCurrency as currency } from '../utils/formatCurrency';
import { CartContext } from '../store/CartContext';
import { getUpsellTotal } from '../utils/amountHelper';
import useBrand from '../hooks/useBrand';
// import CouponField from './CouponField';

export default function CartTotal() {
  const [cartState, cartDispatch] = useContext(CartContext);
  const isCartLoading = cartState.isLoading;
  const isCartValid = cartState.validation.isValid;
  const { domain } = useBrand();

  const VITE_APP_MODE = import.meta.env.MODE;
  const NODE_ENV = process.env.NODE_ENV.toLowerCase();
  const isDevMode = import.meta.env.DEV;

  let shopUrl = `https://dlshop.${domain}`;

  if (VITE_APP_MODE === 'localDev' && isDevMode) {
    shopUrl = `https://shop.${domain}`;
  }
  if (NODE_ENV === 'staging') {
    shopUrl = `https://mlshop.${domain}`;
  }
  if (NODE_ENV === 'production') {
    shopUrl = `https://shop.${domain}`;
  }

  const viewedUpsells = cartState.userExperience.viewedUpsells;

  const checkUrl = `${shopUrl}/checkout/?cartId=${cartState.cartId}`;

  const handleCheckout = (event) => {
    event.preventDefault();

    if (isCartValid) {
      if (viewedUpsells) {
        sessionStorage.setItem('viewCheckout', true);
        window.location.href = checkUrl;
      } else {
        cartDispatch({
          type: 'EXPERIENCE',
          payload: {
            showUpsellModal: true,
          },
        });
      }
    } else {
      cartDispatch({
        type: 'VALIDATION',
        payload: {
          ...cartState.validation,
          showErrors: true,
          formTouched: true,
        },
      });
    }
  };

  const savings = cartState.cartProducts?.reduce((acc, cur) => {
    const upsellSavingsTotal = cur.upSellProducts
      .filter((item) => item.isSelected)
      .reduce((acc2, cur2) => {
        return acc2 + cur2.unitDiscountAmount * cur2.quantity;
      }, 0);
    return acc + upsellSavingsTotal + cur.unitDiscountAmount * cur.quantity;
  }, 0);

  const subTotal = cartState.cartProducts?.reduce((acc, cur) => {
    const upsellTotal = getUpsellTotal(cur.upSellProducts);
    if (cartState.hideFeeInCart) {
      return acc + cur.unitPriceWithDiscountAndFeeIfHidden * cur.quantity + upsellTotal;
    }
    return acc + cur.unitPriceWithDiscountAndFee * cur.quantity + upsellTotal;
  }, 0);

  return (
    <>
      <div className="card card-summary cart-summary mb-3 rounded-0 border-0 border-top border-bottom">
        <div className="card-body p-0">
          <ul className="list-group list-group-flush m-0">
            {/* <li className="list-group-item subtotal px-3">
              <div className="item-details order-tally">Subtotal</div>
              <div className="item-amount text-end">{subTotal && currency(subTotal)}</div>
            </li> */}
            {/* <li className="list-group-item shipping px-3">
              <div className="item-details order-tally">Shipping & Handling</div>
              <div className="item-amount text-end">FREE</div>
            </li>
            <li className="list-group-item tax px-3">
              <div className="item-details order-tally">Tax</div>
              <div className="item-amount text-end">$0.00</div>
            </li> */}
            <li className="list-group-item total px-3 fs-5 fw-700">
              <div className="item-details order-tally">Total</div>
              <div className="item-amount text-end">
                {cartState.isLoading ? (
                  <span className="animated-placeholder rounded-2 w-70 height-lg" style={{ minWidth: '110px' }}></span>
                ) : (
                  currency(subTotal)
                )}
              </div>
            </li>
            {savings > 0 && (
              <li className="list-group-item fw-bolder text-success px-3 fs-6">
                <div className="item-details order-tally">You Save</div>
                <div className="item-amount text-end">
                  {cartState.isLoading ? (
                    <span className="animated-placeholder rounded-2 w-70 height-lg" style={{ minWidth: '110px' }}></span>
                  ) : (
                    currency(savings)
                  )}
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>

      {/* <CouponField /> */}

      {/* <a
        href={checkUrl}
        className={`checkout-btn btn btn-lg btn-success w-100 mt-2 ${isCartLoading ? 'disabled' : ''}`}
        onClick={handleCheckout}
      >
        Checkout Now
      </a> */}

      <button
        type="button"
        className={`checkout-btn btn btn-lg btn-success w-100 mt-2 ${isCartLoading ? 'disabled' : ''}`}
        onClick={handleCheckout}
        name="Checkout Now"
      >
        Checkout Now
      </button>
    </>
  );
}
