import { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { CartContext } from '../../store/CartContext';
import { Toast } from 'bootstrap';
import { getUpsellTotal } from '../../utils/amountHelper';
import { formatCurrency as currency } from '../../utils/formatCurrency';

export default function ToastNotification() {
  const [cartState, cartDispatch] = useContext(CartContext);

  const [toastNotification, setToastNotification] = useState(null);

  const toastElem = useRef();

  useEffect(() => {
    setToastNotification(new Toast(toastElem.current, { delay: 4000 }));

    toastElem.current.addEventListener('hidden.bs.toast', () => {
      // trigger to hide the toast notification
      cartDispatch({
        type: 'EXPERIENCE',
        payload: {
          showNotification: false,
        },
      });
    });
  }, [cartDispatch]);

  useEffect(() => {
    if (!cartState.userExperience.showNotification && toastNotification) {
      toastNotification.hide();
    }
    if (cartState.userExperience.showNotification && toastNotification) {
      toastNotification.show();
    }
  }, [cartState.userExperience.showNotification, toastNotification]);

  const handleViewCart = (e) => {
    e.preventDefault();

    const orderSummary = document.getElementById('order-summary');

    if (orderSummary) {
      orderSummary.scrollIntoView({
        behavior: 'smooth',
      });
    }

    toastNotification.hide();

    // trigger to expand of order summary
    cartDispatch({
      type: 'EXPERIENCE',
      payload: {
        expandOrderSummary: true,
      },
    });
  };

  const subTotal = useMemo(
    () =>
      cartState.cartProducts?.reduce((acc, cur) => {
        const upsellTotal = getUpsellTotal(cur.upSellProducts);
        if (cartState.hideFeeInCart) {
          return acc + cur.unitPriceWithDiscountAndFeeIfHidden * cur.quantity + upsellTotal;
        }
        return acc + cur.unitPriceWithDiscountAndFee * cur.quantity + upsellTotal;
      }, 0),
    [cartState.cartProducts, cartState.hideFeeInCart]
  );

  return (
    <div className="toast-container position-fixed d-lg-none bottom-0 end-0 p-3">
      <div
        ref={toastElem}
        className="toast align-items-center bg-theme-success border-0"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="toast-header bg-theme-success">
          <strong className="me-auto text-dark fw-700">Your cart was updated!</strong>
          <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div className="toast-body d-flex justify-content-between">
          <div className="fw-700 pe-3">Order Total: {currency(subTotal)}</div>
          <div>
            <a className="text-reset small" href="#" onClick={handleViewCart}>
              &raquo;&nbsp;View Summary
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
