import { useState, useEffect, useContext, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import cookies from '../../utils/cookies';
import { Helmet } from 'react-helmet-async';
import Error from '../../components/ErrorBoundary';
import CartItem from '../../components/CartItem';
import CartTotal from '../../components/CartTotal';
import CartEmpty from '../../components/CartEmpty';
import CartSkeleton from '../../components/CartSkeleton';
import API from '../../services/apiService';
import { CartContext } from '../../store/CartContext';
import useBrand from '../../hooks/useBrand';

import ValidationModal from '../../components/ValidationModal';
import AttestationForm from '../../components/AttestationForm';

const Cart = () => {
  const { domain } = useBrand();
  const [searchParams, setSearchParams] = useSearchParams();
  const { cartId, productId, tp, coupon, groupId, host, bulk, testVariant } = Object.fromEntries([...searchParams]);
  const myCartId = cartId || cookies.get('cartId');
  const bulkCartId = cookies.get('bulkCartId');

  // global setting to hide the fees from the cart
  const hideFeeInCart = true;

  const [cartState, cartDispatch] = useContext(CartContext);

  const [isSingle, setIsSingle] = useState(true);
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const [isCartLoaded, setIsCartLoaded] = useState(false);

  // adding current-page-{pageName} class for styling the header breadcrumb section
  useEffect(() => {
    document.body.classList.add('current-page-cart');
  }, []);

  // setting testVariant for testing
  useEffect(() => {
    if (testVariant) {
      cookies.set('testVariant', testVariant);
    }
  }, [testVariant]);

  const storeCartData = useCallback(
    (cartData) => {
      let cart = cartData;

      // console.log('cart', cart);

      // this sets and updates the local cart store making it available for all the components
      cartDispatch({
        type: 'UPDATE',
        payload: {
          ...cart,
          hideFeeInCart,
          isLoading: false,
          isPageShowing: false,
        },
      });

      cookies.set('cartId', cart.cartId, 7);

      if (bulkCartId !== cart.cartId) {
        bulkCartId && cookies.delete('bulkCartId');
      }
      if (bulk === '1') {
        cookies.set('bulkCartId', cart.cartId, 7);
      }

      setIsSingle(cart?.cartProducts?.length > 1 ? false : true);
      setIsCartLoaded(true);

      // this removes the query strings form the URL
      if ([...searchParams].length > 0) {
        setSearchParams({}, { replace: true });
      }
    },
    [bulk, bulkCartId, cartDispatch, hideFeeInCart, searchParams, setSearchParams]
  );

  const getCart = useCallback(
    (myCartId) => {
      API.getCart(myCartId)
        .then((res) => {
          const cartData = res.data;
          sessionStorage.removeItem('viewCheckout');
          storeCartData(cartData);
        })
        .catch((err) => {
          if (err?.name === 'CanceledError') {
            console.error('Request Aborted');
          } else if (err?.name === 'AxiosError') {
            // console.error(err);
            console.error(err?.response?.data);
          } else {
            console.error(err);
          }

          // show error message or reload the page
          cartDispatch({
            type: 'ERROR',
            payload: {
              isLoading: false,
              isPageShowing: false,
              isError: true,
              error: err?.response?.data || 'General Error',
            },
          });

          if (err?.response?.data?.status >= 400) {
            console.error('Your cart is empty!');
            setIsCartEmpty(true);
          }
        });
    },
    [cartDispatch, storeCartData]
  );

  const handleBackBtn = useCallback(
    (myCartId) => {
      // console.log('SET Pageshow event listener');

      if (myCartId && sessionStorage.getItem('viewCheckout') === 'true') {
        // console.log('IF Pageshow Cart useEffect');

        sessionStorage.removeItem('viewCheckout');
        // window.location.reload();

        cartDispatch({
          type: 'UPDATE',
          payload: {
            isLoading: true,
            isPageShowing: true,
          },
        });

        getCart(myCartId);
      }
    },
    [cartDispatch, getCart]
  );

  // get the cart using the query or cookie cartId
  useEffect(() => {
    // console.log('Normal Cart useEffect');

    // removing eventListener before adding one to prevent duplicates
    window.removeEventListener('pageshow', () => handleBackBtn(myCartId));
    window.addEventListener('pageshow', () => handleBackBtn(myCartId));

    // if only cartId is available, fetch the cart
    if (
      myCartId &&
      !sessionStorage.getItem('viewCheckout') &&
      !(productId || tp || groupId || coupon || host) &&
      !isCartLoaded
    ) {
      // console.log('IF Normal Cart useEffect');

      getCart(myCartId);
    }
  }, [coupon, getCart, groupId, handleBackBtn, host, isCartLoaded, myCartId, productId, tp]);

  // create or update an existing cart based on URL query string
  useEffect(() => {
    // if there is no cartId but there is is a productId query string, create a new cart
    if (!myCartId && productId) {
      const productIds = productId ? productId.toLowerCase().split(',') : [];
      const cartData = {
        cartId: null, // set to null because we are creating a new cart
        productIds: productIds,
        pricingVariant: tp || null,
        groupId: groupId || null,
        coupon: coupon || null,
        sourceHost: host || domain,
      };
      API.createCart(cartData)
        .then(async (res) => {
          const cartData = res.data;
          storeCartData(cartData);
        })
        .catch((err) => {
          console.error(err?.response?.data);

          // show error message or reload the page
          cartDispatch({
            type: 'ERROR',
            payload: {
              isLoading: false,
              isPageShowing: false,
              isError: true,
              error: err?.response?.data || 'General Error',
            },
          });

          if (err?.response?.data?.status >= 400) {
            console.error('Your cart is empty!');
            setIsCartEmpty(true);
          }
        });
    }

    // if there is an exising cartId and there's a productId or price modifiers, update the cart
    if (myCartId && (productId || tp || groupId || coupon || host)) {
      const productIds = productId ? productId.toLowerCase().split(',') : [];
      const cartData = {
        cartId: myCartId,
        productIds: productIds,
        pricingVariant: tp || null,
        groupId: groupId || null,
        coupon: coupon || null,
        sourceHost: host || domain,
      };
      API.updateCart(cartData)
        .then(async (res) => {
          const cartData = res.data;
          storeCartData(cartData);
        })
        .catch((err) => {
          console.error(err?.response?.data);

          // show error message or reload the page
          cartDispatch({
            type: 'ERROR',
            payload: {
              isLoading: false,
              isPageShowing: false,
              isError: true,
              error: err?.response?.data || 'General Error',
            },
          });

          if (err?.response?.data?.status >= 400) {
            console.error('Your cart is empty!');
            setIsCartEmpty(true);
          }
        });
    }
  }, [cartDispatch, coupon, domain, groupId, host, myCartId, productId, storeCartData, tp]);

  const cartItemsWithoutReplaceUpsell =
    cartState.cartProducts?.filter(
      (product) =>
        product.upSellProducts.length === 0 ||
        !product.upSellProducts.some((upsell) => {
          return upsell.upsellType === 'Replace';
        })
    ) || [];
  const cartItemsWithReplaceUpsell =
    cartState.cartProducts?.filter((product) => product.upSellProducts.some((upsell) => upsell.upsellType === 'Replace')) ||
    [];

  const sortedItems = cartState.cartProducts ? [...cartItemsWithoutReplaceUpsell, ...cartItemsWithReplaceUpsell] : undefined;

  const cartItems = sortedItems?.map((product) => {
    return <CartItem item={product} isSingle={isSingle} hideFeeInCart={hideFeeInCart} key={product.cart_ProductId} />;
  });

  // if cart is not empty render the cart details
  if (cartItems && cartItems.length > 0) {
    return (
      <>
        <Helmet>
          <title>Cart Details</title>
        </Helmet>

        <div className={`container-fluid but-fixed ${!cartItems ? 'cart-skeleton' : ''}`}>
          <div className="row justify-content-center">
            <div className="col-12 pt-3 pt-md-4">
              <div id="items-header" className="row d-none d-md-flex justify-content-between mb-1 px-3 fw-bold text-muted">
                <div className="product-details">Product Name</div>
                <div className="product-qty product-qty-label text-center">Quantity</div>
                <div className="product-price text-end">Price</div>
              </div>
              {cartItems}
            </div>
          </div>

          <div className="row justify-content-center mb-4">
            <div className="col-12 col-lg-8 pe-lg-4">
              <AttestationForm />
            </div>
            <aside className="col-sm-10 col-md-8 col-lg-4 mt-3 mb-5">
              <CartTotal />
            </aside>
          </div>
        </div>

        <ValidationModal />
      </>
    );
  }

  // if the cart is empty show empty cart message
  if (isCartEmpty || (!myCartId && !productId) || (cartItems && cartItems.length === 0)) {
    return <CartEmpty />;
  }

  // preloader skeleton screen
  return <CartSkeleton />;
};

export default Cart;

export const ErrorBoundary = () => {
  return <Error fromPage="Cart Page" />;
};
