export const initialCart = {
  isLoading: true,
  isUpdatingCart: false,
  userExperience: {
    viewedUpsells: false,
    showUpsellModal: false,
    showNotification: false,
    expandOrderSummary: false,
  },
  validation: {
    isValid: true,
    errors: {},
    showErrors: false,
    formTouched: false,
  },
};

export const cartReducer = (state, action) => {
  // console.log('cartContext', state, action);

  switch (action.type) {
    case 'UPDATE': {
      return {
        ...state,
        ...action.payload,
        isError: false,
      };
    }
    case 'VALIDATION': {
      return {
        ...state,
        validation: {
          ...state.validation,
          ...action.payload,
          errors: { ...action.payload.errors },
        },
      };
    }
    case 'EXPERIENCE': {
      return {
        ...state,
        userExperience: {
          ...state.userExperience,
          ...action.payload,
        },
      };
    }
    case 'ERROR': {
      return {
        ...state,
        ...action.payload,
        isError: true,
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};
