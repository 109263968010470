import { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { Collapse } from 'bootstrap';
import { formatCurrency as currency } from '../../utils/formatCurrency';
import { CartContext } from '../../store/CartContext';
import { getUpsellTotal } from '../../utils/amountHelper';
import OrderSummaryItem from './OrderSummaryItem';

// import CheckoutBtn from './CheckoutBtn';
// import CouponField from '../CouponField';

export default function OrderSummary() {
  const [cartState, cartDispatch] = useContext(CartContext);

  const isSingle = cartState?.cartProducts?.length > 1 ? false : true;

  const [isSummaryCollapsed, setIsSummaryCollapsed] = useState(true);
  const [collapse, setCollapse] = useState(null);
  const itemsCollapse = useRef();

  // const appliedDiscounts = useMemo(
  //   () =>
  //     cartState.cartProducts?.reduce((acc, cur) => {
  //       const isCouponDiscount = cur.discountSource === 'Coupon';
  //       const discounts = {
  //         ...acc,
  //         ...(cur.unitDiscountAmount > 0
  //           ? {
  //               [isCouponDiscount ? 'Coupon' : `${cur.discountSource} Discount`]: isCouponDiscount
  //                 ? [...(acc.Coupon ? acc.Coupon : []), cur.discountCode]
  //                 : cur.discountCode,
  //             }
  //           : {}),
  //       };

  //       const upsellDiscounts = cur.upSellProducts
  //         .filter((item) => item.isSelected)
  //         .reduce((acc2, cur2) => {
  //           const isCouponDiscount = cur2.discountSource === 'Coupon';
  //           return {
  //             ...acc2,
  //             ...(cur2.unitDiscountAmount > 0
  //               ? {
  //                   [isCouponDiscount ? 'Coupon' : `${cur2.discountSource} Discount`]: isCouponDiscount
  //                     ? [...(acc2?.Coupon ? acc2.Coupon : []), cur2.discountCode]
  //                     : cur2.discountCode,
  //                 }
  //               : {}),
  //           };
  //         }, {});

  //       return {
  //         ...discounts,
  //         ...upsellDiscounts,
  //       };
  //     }, {}),
  //   [cartState.cartProducts]
  // );

  // console.log(appliedDiscounts);

  useEffect(() => {
    setCollapse(
      new Collapse(itemsCollapse.current, {
        toggle: false,
      })
    );

    // using 'show.bs.collapse' because the effect is immediate
    itemsCollapse.current.addEventListener('show.bs.collapse', () => {
      setIsSummaryCollapsed(false);
    });
    // using 'hidden.bs.collapse' because the effect is after it collapses
    itemsCollapse.current.addEventListener('hidden.bs.collapse', () => {
      setIsSummaryCollapsed(true);

      // trigger to expand of order summary
      cartDispatch({
        type: 'EXPERIENCE',
        payload: {
          expandOrderSummary: false,
        },
      });
    });
  }, [cartDispatch]);

  // open the order summary if user clicked view summary from the toast notification
  useEffect(() => {
    if (cartState.userExperience.expandOrderSummary) {
      collapse.show();
    }
  }, [cartState.userExperience.expandOrderSummary, collapse]);

  const toggleCollapse = () => {
    collapse.toggle();
  };

  const appliedCoupons = useMemo(() => {
    const coupons = [];

    cartState.cartProducts?.forEach((item) => {
      const isCouponApplied = item.discountSource === 'Coupon' && item.unitDiscountAmount > 0;

      if (isCouponApplied) {
        coupons.push(item.discountCode);
      }

      item.upSellProducts
        .filter((item) => item.isSelected)
        .forEach((upsell) => {
          const isCouponApplied = upsell.discountSource === 'Coupon' && upsell.unitDiscountAmount > 0;

          if (isCouponApplied) {
            coupons.push(upsell.discountCode);
          }
        });
    });

    return [...new Set(coupons)].length ? [...new Set(coupons)].join(', ') : null;
  }, [cartState.cartProducts]);

  const savings = useMemo(
    () =>
      cartState.cartProducts?.reduce((acc, cur) => {
        const upsellSavingsTotal = cur.upSellProducts
          .filter((item) => item.isSelected)
          .reduce((acc2, cur2) => {
            return acc2 + cur2.unitDiscountAmount * cur2.quantity;
          }, 0);
        return acc + upsellSavingsTotal + cur.unitDiscountAmount * cur.quantity;
      }, 0),
    [cartState.cartProducts]
  );

  const subTotal = useMemo(
    () =>
      cartState.cartProducts?.reduce((acc, cur) => {
        const upsellTotal = getUpsellTotal(cur.upSellProducts);
        if (cartState.hideFeeInCart) {
          return acc + cur.unitPriceWithDiscountAndFeeIfHidden * cur.quantity + upsellTotal;
        }
        return acc + cur.unitPriceWithDiscountAndFee * cur.quantity + upsellTotal;
      }, 0),
    [cartState.cartProducts, cartState.hideFeeInCart]
  );

  const cartItemsWithoutReplaceUpsell =
    cartState.cartProducts?.filter(
      (product) =>
        product.upSellProducts.length === 0 ||
        !product.upSellProducts.some((upsell) => {
          return upsell.upsellType === 'Replace';
        })
    ) || [];
  const cartItemsWithReplaceUpsell =
    cartState.cartProducts?.filter((product) => product.upSellProducts.some((upsell) => upsell.upsellType === 'Replace')) ||
    [];

  const sortedItems = cartState.cartProducts ? [...cartItemsWithoutReplaceUpsell, ...cartItemsWithReplaceUpsell] : undefined;

  const cartItems = sortedItems?.map((product) => {
    return <OrderSummaryItem item={product} isSingle={isSingle} key={product.cart_ProductId} />;
  });

  return (
    <>
      {/* <div
        className={`card card-summary sticky-top py-1 mb-3 ${cartState.isLoading ? 'is-updating-cart' : ''}`}
        id="order-summary"
      > */}
      <div className={`card card-summary sticky-top py-1 mb-3`} id="order-summary">
        <div className="card-body py-0 px-3">
          <ul className="list-group list-group-flush bg-transparent m-0">
            <li className="list-group-item fw-700 align-items-center fs-6 px-0">
              <div className="item-details w-100">
                <h3 className="fs-6 fw-700 my-1 d-none d-lg-block">Order Summary</h3>
                <button
                  className={`summary-collapse-toggle fs-6 fw-700 text-start my-1 d-lg-none ${
                    !isSummaryCollapsed ? 'is-shown' : ''
                  }`}
                  type="button"
                  onClick={toggleCollapse}
                >
                  Order Summary
                </button>
              </div>
              {isSummaryCollapsed && (
                <div className="item-amount text-end d-lg-none">
                  {cartState.isLoading ? (
                    <span className="animated-placeholder rounded-2 w-70 height-md" style={{ minWidth: '70px' }}></span>
                  ) : (
                    currency(subTotal)
                  )}
                </div>
              )}
            </li>

            {/* <li className="list-group-item fw-700 fs-6 px-0">
              <div className="item-details">Order Summary</div>
              <div className="item-amount text-end d-lg-none">
                {cartState.isLoading ? (
                  <span className="animated-placeholder rounded-2 w-70 height-md" style={{ minWidth: '70px' }}></span>
                ) : (
                  currency(subTotal)
                )}
              </div>
            </li> */}

            {/* {cartItems} */}
            <li className="list-group-item p-0 collapse" ref={itemsCollapse} id={`order-summar-items-collapse`}>
              <ul
                className={`list-group list-group-flush bg-transparent w-100 m-0`}
                // className={`upsells-wrapper mt-3 ${!isSingle && 'collapse'}`}
                // className={`upsells-wrapper mt-3`}
              >
                {cartItems}

                <li className="list-group-item total fw-700 fs-6 px-0">
                  <div className="item-details">Total</div>
                  <div className="item-amount text-end">
                    {cartState.isLoading ? (
                      <span className="animated-placeholder rounded-2 w-70 height-md" style={{ minWidth: '70px' }}></span>
                    ) : (
                      currency(subTotal)
                    )}
                  </div>
                </li>
              </ul>
            </li>

            {savings > 0 && (
              <li className="list-group-item savings-display">
                <div className="item-details fw-700 text-success">
                  You Save
                  {appliedCoupons && <div className="coupon-display fw-400">Coupon: {appliedCoupons}</div>}
                </div>
                <div className="item-amount text-end fw-700 text-success">
                  {cartState.isLoading ? (
                    <span className="animated-placeholder rounded-2 w-70 height-sm" style={{ minWidth: '70px' }}></span>
                  ) : (
                    currency(savings)
                  )}
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>

      {/* <CouponField /> */}

      {/* <CheckoutBtn /> */}
    </>
  );
}
