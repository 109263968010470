import { useContext, useMemo } from 'react';
import RelatedUpsellItem from './RelatedUpsellItem';
import { CartContext } from '../../store/CartContext';

export default function RelatedUpsells() {
  const [cartState] = useContext(CartContext);

  const cartProductIds = useMemo(() => cartState.cartProducts.map((item) => item.productId), [cartState.cartProducts]);

  const allUpsells = cartState.cartProducts.reduce((acc, cur) => [...acc, ...cur.upSellProducts], []);

  // filter out any upsell that is already in the cart
  // filter out any duplicate related upsells
  const upsellsForDisplay = allUpsells
    .filter((upsell) => upsell.upsellSectionId === 5 && !cartProductIds.includes(upsell.productId))
    .reduce((acc, cur) => {
      if (!acc.some((item) => item.productId === cur.productId)) {
        acc.push(cur);
      }
      return acc;
    }, [])
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex)
    .map((upsell) => {
      return <RelatedUpsellItem upsell={upsell} key={upsell.productId} />;
    });

  const hasRelated = upsellsForDisplay.length > 0;

  return (
    <>
      {hasRelated && (
        <div className="upsell-group related-group mb-3">
          <h3 className="fs-4 fw-400 mt-4 mb-2">Others Also Purchased:</h3>
          {upsellsForDisplay}
        </div>
      )}
    </>
  );
}
